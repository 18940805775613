<template>
  <FullCalendar :options="calendarOptions">
    <template v-slot:eventContent="arg">
      <div>
        <b>{{ arg.event.title }}</b>
      </div>
    </template>
  </FullCalendar>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  components: {
    FullCalendar,
  },
  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        eventClick: this.handleEventClick,
        events: [],
      },
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loadData(newValue);
      },
    },
  },
  methods: {
    loadData(data) {
      this.calendarOptions.events = data;
    },
    handleEventClick: function (arg) {
      this.$emit("eventHandler", {
        eventId: arg.event.id,
        date: arg.event.start,
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
