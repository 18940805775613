<template>
  <v-dialog v-model="visible" width="auto" content-class="my-dialog">
    <v-card color="primary" v-if="loading">
      <v-card-text>
        Please stand by
        <v-progress-linear indeterminate color="white" class="mb-0" />
      </v-card-text>
    </v-card>
    <v-container v-else class="content-area">
      <v-row>
        <v-col cols="12">
          <h3>Auditor results history</h3>
        </v-col>
        <v-col cols="12">
          <calendar
            @month="month = $event"
            @eventHandler="loadBatch($event)"
            :data="data"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            rounded
            color="primary"
            class="text-capitalize bottom-btn"
            @click="onClose"
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import Calendar from "@/components/Calendar";
import { getAuditorResultsHistory } from "@/api/monitor/auditor";
import { Errors } from "@/utils/error";

export default {
  name: "timetravel",
  components: {
    Calendar,
  },
  props: {
    show: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    type: {
      type: Number,
      default: function () {
        return undefined;
      },
    },
  },
  watch: {
    show: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.visible = newValue;
        if (newValue) {
          this.loadData();
        }
      },
    },
    visible: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          this.onClose();
        }
      },
    },
  },
  data() {
    return {
      visible: false,
      loading: true,
      data: [],
      month: undefined,
    };
  },
  methods: {
    loadData() {
      this.loading = false;
      getAuditorResultsHistory(this.type, this.month)
        .then((response) => {
          this.data = response.data;
          this.month = response.month;
          this.loading = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    loadBatch(calendarEvent) {
      this.$emit("loadBatch", calendarEvent);
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  padding: 0.5rem;
  border-radius: 0.4rem;
}
.theme--dark {
  .content-area {
    background-color: #1e1e1e;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
  }
}
.theme--light {
  .content-area {
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
  }
}
.content-area {
  .bottom-btn {
    float: right;
  }
}
</style>
<style>
.my-dialog {
  overflow-y: visible;
}
</style>
